<template>
  <v-container>
    <div class="confirm-container mx-auto">
      <v-row no-gutters class="text-center">
        <v-col cols="12">
          <h1>{{ $t("selfscanning.welcome.title", [user.firstName]) }}</h1>
          <p>
            <strong>{{ $t("selfscanning.welcome.subtitle") }}</strong>
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters class="btn-container align-center justify-center">
        <div class="d-flex flex-column">
          <img
            class="selfscanning-img rounded-lg"
            src="/img_layout/zebra-sc.jpg"
          />
          <!-- <v-btn
            large
            depressed
            color="primary"
            class="rounded-pill d-block mx-auto d-flex align-center"
            min-width="200"
            :to="{ path: '/' }"
            >{{ $t("register.completed.goToHomeSelfScannig") }}</v-btn
          > -->
          <a
            href="/"
            class="rounded-pill px-6 v-btn v-btn--has-bg theme--light v-size--large primary"
            >{{ $t("register.completed.goToHomeSelfScannig") }}</a
          >
        </div>
      </v-row>
    </div>
  </v-container>
</template>
<style lang="scss">
.selfscanning-img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  max-height: 220px;
}
.confirm-container {
  width: 60%;
  padding: 35px 0 0x;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 100%;
  }
  h1 {
    font-size: 50px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 25px;
    }
  }
  .msg-body,
  .confirm,
  .warning-text {
    font-size: 20px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 16px;
      line-height: 1.15;
    }
  }
  .warning-text {
    color: #f80100;
  }
}
</style>
<script>
// import CategoryTitle from "@/components/category/CategoryTitle.vue";
import { mapGetters } from "vuex";
import clickHandler from "~/mixins/clickHandler";
import categoryMixins from "~/mixins/category";
export default {
  name: "WelcomeSelfScanning",
  mixins: [categoryMixins, clickHandler],
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  }
};
</script>
